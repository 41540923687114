import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../redux/actions"
import { RootState } from "../../redux/store"

const mapState = (state: RootState, props: RouteComponentProps) => {
  const location = props.location?.state as {
    action: "like" | "publish" | null
    from?: string
  }
  const from = location?.from
  const fromAction = location?.action

  return {
    isFetching: state.auth.fetching,
    isOpen: state.auth.isOpen,
    fromAction,
    from,
  }
}

const mapDispatch = (dispatch: any) => ({
  onSubmit: (params: { email: string; password: string }) =>
    dispatch(actions.auth.fetchAuthenticate(params)),
  onClickGoogle: () => {
    dispatch(actions.auth.fetchAuthenticateWithGoogle())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
