import React from "react"
import { PageProps } from "gatsby"

import { Signin } from "../routes/Signin"
import { Seo } from "../components/Seo/Seo"

export default ({ location }: PageProps) => (
  <>
    <Seo
      title="Se connecter | Temple du Haïku"
      description="Pour pouvoir publier et aimer des haïkus publiés par la communauté, vous devez vous connecter"
    />
    <Signin location={location} />
  </>
)
