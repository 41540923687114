import { Link } from "gatsby";
import React, { useState } from "react";
import { connector, ContainerProps } from "./containers/Signin.container";
import * as Buttons from "../components/Buttons";
import { ArrowLeft } from "../components/Icons/ArrowLeft";
import { RouteComponentProps } from "@reach/router";

type SigninProps = {
  isFetching: boolean;
  fromAction: "like" | "publish" | null;
  from?: string;
  onSubmit: (params: { email: string; password: string }) => any;
  onClickGoogle: Function;
};

const SigninWrapper: React.FC<SigninProps> = ({
  onSubmit,
  from,
  fromAction,
  onClickGoogle,
  isFetching,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      password,
      email,
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-100 sm:px-6 lg:px-8">
      <div className="fixed top-0 w-full">
        <div className="container">
          <Link to={from || '/'}>
            <button className="px-4 py-4">
              <ArrowLeft className="text-gray-600" />
            </button>
          </Link>
        </div>
      </div>
      <div className="w-full max-w-md">
        <div>
          <h1 className="mt-6 text-3xl font-extrabold leading-9 text-center text-gray-900">
            Se connecter
            {fromAction === "like" && <p>pour mettre un j'aime</p>}
            {fromAction === "publish" && <p>pour publier un haïku</p>}
          </h1>
          <p className="mt-2 text-sm leading-5 text-center text-gray-600">
            Ou {""}
            <Link
              to="/creer-un-compte/"
              className="font-bold text-gray-600 underline transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:underline"
            >
              créez vous un compte
            </Link>
          </p>
        </div>

        <Buttons.Google
          label="Se connecter avec Google"
          onClick={() => onClickGoogle()}
        />

        <div className="flex items-center justify-between mt-4">
          <span className="w-1/5 border-b lg:w-1/4"></span>

          <span className="text-xs text-center text-gray-500 uppercase">
            ou par email
          </span>

          <span className="w-1/5 border-b lg:w-1/4"></span>
        </div>

        <form className="mt-4" onSubmit={submit}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm">
            <div>
              <input
                aria-label="Adresse email"
                name="email"
                type="email"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Adresse email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </div>
            <div className="-mt-px">
              <input
                aria-label="Mot de passe"
                name="password"
                type="password"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Mot de passe"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </div>
          </div>

          <div className="mt-6">
            <Buttons.Signin label={"Se connecter"} isFetching={isFetching} />
          </div>

          <div className="flex items-center justify-center mt-6">
            <div className="text-sm leading-5">
              <Link
                to="/mot-de-passe-oublie/"
                className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline"
              >
                Mot de passe oublié ?
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const SigninContainer: React.FC<ContainerProps & RouteComponentProps> = (
  props
) => <SigninWrapper {...props} />;

export const Signin = connector(SigninContainer);
